import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 导入自定义图标
import myIcon from '@/components/common/my-icon.vue'
// 导入公共框架
import baseLayout from '@/components/layout/base-layout.vue'
// 导入bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/scss/bootstrap.scss'
// 导入AOS动画
import AOS from 'aos'
import 'aos/dist/aos.css'
// 导入api接口
import api from './api'
// 导入element-plus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// 导入时间转换插件
import moment from 'moment'

const vue = createApp(App)
vue.config.globalProperties.$api = api // 自定义添加
vue.config.globalProperties.$moment = moment
vue.component('my-icon', myIcon)
vue.component('base-layout', baseLayout)
vue.AOS = new AOS.init()
vue.use(AOS).use(ElementPlus).use(store).use(router).mount('#app')