import request from '../utils/request.js'

export default {
  // 轮播图
  getswiperlist(params={}) {    
    return request({
      method: 'get',     
      url: '/Picmn/index', 
      params: params
    })    
  },
  // 服务管理
  getmnlist(params={}) {    
    return request({
      method: 'get',     
      url: '/Manages/index', 
      params: params
    })    
  },
  // 问答管理
  getqalist(params={}) {    
    return request({
      method: 'get',     
      url: '/QA/index', 
      params: params
    })    
  },
  // 合作案例
  gethzlist(params={}) {    
    return request({
      method: 'get',     
      url: '/Example/index', 
      params: params
    })    
  },
  // 公司简介
  getjjlist(params={}) {    
    return request({
      method: 'get',     
      url: '/CompanyAbout/index', 
      params: params
    })    
  },
  // 公司文化
  getwhlist(params={}) {    
    return request({
      method: 'get',     
      url: '/CompanyWen/index', 
      params: params
    })    
  },
  // 公司荣耀
  getrylist(params={}) {    
    return request({
      method: 'get',     
      url: '/CompanyHonour/index', 
      params: params
    })    
  },
  // 在线留言
  putmessage(data) {    
    return request({
      method: 'post',     
      url: '/Message/add', 
      data: data
    })    
  },
  // 栏目
  getnavlist(params={}) {    
    return request({
      method: 'get',     
      url: '/Column/index', 
      params: params
    })    
  },
  // 文章类别
  getwzlblist(params={}) {    
    return request({
      method: 'get',     
      url: '/Articletype/index', 
      params: params
    })    
  },
  // 文章管理
  getwzlist(params={}) {    
    return request({
      method: 'get',     
      url: '/Article/index', 
      params: params
    })    
  },
  // 文章详细
  getwzdetail(params = {}) {
    return request({
      method: 'get',     
      url: '/Article/view', 
      params: params
    })  
  }


  
}
