<template>
  <span :class="['iconfont', id, color]" :style="{ fontSize: size+'px' }" ></span>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
    size: {
      type: [Number, String],
      default: 40,
    },
  },
};
</script>

<style>
</style>
