<template>
  <div>
    <!--头部Banner-->
    <header-banner :src="bannerSrc" />
    <!--头部菜单-->
    <header-nav :logoSrc="logoSrc" :navList="navList" />
    <!--内容插槽-->
    <slot name="main"></slot>
    <!--尾部-->
    <Footer />
  </div>
</template>

<script>
import HeaderBanner from '@/components/header-banner/header-banner.vue' ;
import HeaderNav from '@/components/header-nav/header-nav.vue';
import Footer from '@/components/footer/footer.vue';
export default {  
  components: {
    HeaderBanner,
    HeaderNav,
    Footer           
  },
  props: {
    bannerSrc: {
      type: String,
      default: "",
    },
    logoSrc: {
      type: String,
      default: "",
    },
    navList: {
      type: Array,
      default: "",
    },
  },
  data() {
    return {
      bannerSrc:require('@/assets/images/header_banner.png'),
      logoSrc: require('@/assets/images/logo.png'),
      navList:[
      {text:"网站首页",path:"/"},
      {text:"工商登记",path:"/pages/gsdengji"},
      {text:"代理记账",path:"/pages/dljizhang"},
      {text:"合作案例",path:"/pages/hzanli"},
      {text:"新闻资讯",path:"/pages/news"},
      {text:"关于我们",path:"/pages/about"},
      {text:"在线留言",path:"/pages/linemessage"}
      ],
    }
  }
    
  };
</script>

<style scoped>
  
  
</style>