import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Index.vue')
  },  
  {
    path: '/pages/gsdengji',
    name: 'gsdengji',
    component: () => import('../views/pages/Gsdengji.vue')
  },
  {
    path: '/pages/dljizhang',
    name: 'jizhang',
    component: () => import('../views/pages/Dljizhang.vue')
  },
  {
    path: '/pages/hzanli',
    name: 'anli',
    component: () => import('../views/pages/Hzanli.vue')
  },
  {
    path: '/pages/news',
    name: 'news',
    component: () => import('../views/pages/News.vue')
  },
  {
    path: '/pages/newsdetail',
    name: 'detail',
    component: () => import('../views/pages/Newsdetail.vue')
  },
  {
    path: '/pages/about',
    name: 'about',
    component: () => import('../views/pages/About.vue')
  },
  {
    path: '/pages/linemessage',
    name: 'message',
    component: () => import('../views/pages/Linemessage.vue')
  },
  
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
