<template>
  <div class="container-fluid bg-banner">
      <div class="container">
        <!-- <img class="img-fluid w-100" :src="src" alt="活动图片" />         -->        
      </div>
    </div>
</template>

<script>
export default {
  props: {
    src:{
      type: String,
      default: ""
    }
  }
}
</script>

<style scoped>  
  .bg-banner {
    background-color: #f5a44b;
    height: 40px;
  }
</style>