// 首先引入 axios
import axios from 'axios'
// 创建实例
const request = axios.create({
    baseURL: 'https://napi.yangchengcaishui.com', // 所有请求的公共地址部分
    // baseURL: process.env.VUE_APP_BASE_API, // 使用动态配置后
    timeout: 　5000 // 请求超时时间 这里的意思是当请求时间超过5秒还未取得结果时 提示用户请求超时
})
// request拦截器
// 请求相关处理 请求拦截 在请求拦截中可以补充请求相关的配置
// interceptors axios的拦截器对象
request.interceptors.request.use(config => {
     // config 请求的所有信息
    // console.log(config);
    return config // 将配置完成的config对象返回出去 如果不返回 请求讲不会进行
}, err => {
    // 请求发生错误时的相关处理 抛出错误
   Promise.reject(err)
})
// response拦截器
request.interceptors.response.use(response => {
    // 我们一般在这里处理，请求成功后的错误状态码 例如状态码是500，404，403
    // res 是所有相应的信息
    // console.log(res)
   return response
}, err => {
    // 服务器响应发生错误时的处理
    return Promise.reject(err)
})
// 导出方法
export default request