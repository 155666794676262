<template>
  <div class="container-fluid bg-footer mt-3 p-3 w-100 h-100" style="height:320px;font-size: 12px;">
    <div class="container">
      <div class="row">
        <div class="col-sm-4">
          <div class="w-100 mt-2 p-3 text-white-50" style="height:120px;">
            <div class="mt-2 border-bottom" style="font-size:16px;">联系方式</div>
            <div class="mt-2" style="font-size:14px;">联系人：袁生</div>
            <div class="mt-1" style="font-size:14px;">电话：020-29054299 &nbsp;&nbsp; 手机：18122436038</div>
            <div class="mt-1" style="font-size:13px;">地址：广州市越秀区寺右新马路25号大院3号兴城大厦703、704、706房
              自编号G001号</div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="w-100 mt-2 p-3 text-white-50" style="height:120px;">
            <div class="mt-2 border-bottom" style="font-size:16px;">主营业务</div>
            <div class="mt-4" style="font-size:14px;">
              专业代办工商注册、商标注册，代理记帐报税、证照变更及公司收购、代办社保、会计秘书服务、企业会计报表审计、企业所得税汇算清缴鉴证、各种专项审计等。</div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="w-100 mt-2 p-3 text-white-50" style="height:120px;">
            <div class="mt-2 border-bottom" style="font-size:16px;">微信公众二维码</div>
            <div class="row align-items-center justify-content-start">
              <div class="col-2">
                <div class="mt-4">
                  <img src="../../assets/images/qr_img.png" width="70" height="70">
                </div>
              </div>
              <div class="col-10">
                <div class="p-3">
                  扫一扫二维码 关注扬程财税
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 分隔线 -->
    <div class="container mt-5">
      <div style="height:1px;background-color:#cdcdcd;"></div>
    </div>

    <div class="container text-center text-white-50 mt-4">
      <!-- <p>广州市扬程财税咨询有限公司 ©2022 版权所有 &nbsp;&nbsp;粤ICP备17067882号</p>
        <p>广州市越秀区寺右新马路25号大院3号兴城大厦G001号 &nbsp;&nbsp;服务电话： 020-37634502</p> -->
      <div class="row">
        <div class="col-sm text-md-end">广州市扬程财税咨询有限公司 ©2022 版权所有</div>
        <div class="col-sm text-md-start"><a href="https://beian.miit.gov.cn"
            style="text-decoration:none;color: #999999;">粤ICP备17067882号</a></div>
      </div>
    </div>

    <!-- 右边回到首页 -->
    <!-- <div class="yb_conct">
        <div class="yb_bar">
          <ul>
            <li class="yb_top" @click="backtop($event)">返回顶部</li>
            <li class="yb_phone">020-37634502</li>
            <li class="yb_QQ">
              <a target="_blank" href="#" title="即刻发送您的需求">在线咨询</a>
            </li>
            <li class="yb_ercode" style="height:53px;">微信二维码 <br>
              <img class="hd_qr" src="../../assets/images/qr_img.png" width="125" alt="关注扬程"> </li>
          </ul>
        </div>
      </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      methods: {
        backtop(event) {

        }
      },
      
    }
  }
}

</script>

<style scoped>
.bg-footer {
    background-color: #323232;
}
/*固定的悬浮窗*/
.yb_conct{position:fixed;z-index:9999999;top:200px;right:-127px;cursor:pointer;transition:all .3s ease;}
.yb_bar ul li{width:180px;height:53px;font:16px/53px 'Microsoft YaHei';color:#fff;text-indent:54px;margin-bottom:3px;border-radius:3px;transition:all .5s ease;overflow:hidden;}
.yb_bar .yb_top{background:#ff4a00 url(../../assets/images/fixCont.png) no-repeat 0 0;}
.yb_bar .yb_phone{background:#ff4a00 url(../../assets/images/fixCont.png) no-repeat 0 -57px;}
.yb_bar .yb_QQ{text-indent:0;background:#ff4a00 url(../../assets/images/fixCont.png) no-repeat 0 -113px;}
.yb_bar .yb_ercode{background:#ff4a00 url(../../assets/images/fixCont.png) no-repeat 0 -169px;}
.hd_qr{padding:0 29px 25px 29px;}
.yb_QQ a{display:block;text-indent:54px;width:100%;height:100%;color:#fff;}
</style>