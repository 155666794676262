<template>
  <div class="container-fluid bg-white sticky-top">
    <div class="container mt-1">
      <div class="d-sm-flex flex-sm-row ">
        <div class="w-50">
          <img class="img-fluid w-100" :src="logoSrc" alt="logo" />
        </div>
        <div class="w-100 d-flex flex-row align-items-center justify-content-end">
          <ul class="nav">
            <li v-for="(item, index) in navList" :key="index">
              <router-link class="link" :to="item.path">{{ item.text }}</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  props:{
    navList: {
      type: Array,
      default: "",
    },
    logoSrc: {
      type: String,
      default: "",
    },
  },
  methods: {
      addClass($event) {
        $event.currentTarget.className = "link animate__animated animate__bounce";
      },
      removeClass($event) {
        $event.currentTarget.className = "link";
      },
    },
}
</script>

<style lang="scss" scoped>
.nav li .link {
    list-style: none;
    width: 100px;
    height: 60px;
    line-height: 60px;
    padding: 0px 10px;
    text-align: center;
    text-decoration: none;
    display: block;
    color: #351e04;
    &.router-link-exact-active {
      color: #E97C20;
      border-bottom: #E97C20 3px solid;
    }
  }
  /* .link:hover {
    background-color: #f5a44b;
    color: #ffffff !important;
    font-weight: bold;
    border-radius: 5px;
  } */
  // .router-link-exact-active {
  //     color: #f5a44b;
  // }
  // .router-link-active {
  //   color: #42b983;
  // }  
</style>